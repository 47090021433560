import React from "react";

import { NotificationItem } from "./notification-item";
import { Notification } from "../../../../models";

interface NotificationListProps {
  data: Array<Notification>;
  onClose: (id: string) => void;
}

export const NotificationList: React.FC<NotificationListProps> = ({
  data,
  onClose,
}) => {
  return (
    <div className="toast-message-holder top-right">
      {data.map(({ id, icon, type, message, colorHex, duration }) => (
        <NotificationItem
          id={id}
          key={id}
          icon={icon}
          type={type}
          message={message}
          onClose={onClose}
          colorHex={colorHex}
          duration={duration}
        />
      ))}
    </div>
  );
};

import { action, makeObservable, observable } from "mobx";

import { BaseListRequest } from "../models";
import { HttpResponse } from "../api";
import { RootStore } from "./index";

import { DEFAULT_RESPONSE_LIST, T } from "../constants";

import { businessesController } from "../api/controllers/businesses";
import {
  Business,
  BusinessResponse,
  GetBusinessRequest,
} from "../models/api/business-settings";
import { Toaster } from "../view/containers";

export class BusinessStore {
  private rootStore: RootStore;

  public businesses = new HttpResponse<BusinessResponse>(DEFAULT_RESPONSE_LIST);

  public businessById = new HttpResponse<Partial<Business>>({});

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      businesses: observable,
      businessById: observable,
      getBusinesses: action.bound,
      setBusinessById: action.bound,
    });
  }

  public setBusinesses(value: HttpResponse<BusinessResponse>): void {
    this.businesses = value;
  }

  public setBusinessById(value: HttpResponse<Partial<Business>>): void {
    this.businessById = value;
  }

  public async getBusinesses(
    params?: BaseListRequest,
  ): Promise<BusinessResponse> {
    this.setBusinesses(this.businesses.fetching());
    const data = await businessesController.getBusinesses(params);
    this.setBusinesses(this.businesses.fetched(data));
    return data;
  }

  public async editBusiness(id: number, data: Business): Promise<any> {
    this.setBusinessById(this.businessById.fetching());
    const response = businessesController.editBusinesses(id, data);

    response
      .then((response) => {
        this.setBusinessById(this.businessById.fetched(response));
        Toaster.success({ message: T.BUSINESS_UPDATE_SUCCESSFULLY });
      })
      .catch((error) => {
        this.setBusinessById(this.businessById.failed(error.message));
        Toaster.error({ message: error.errorMessage });
      });

    return response;
  }

  public async getBusinessById(id: number): Promise<Business> {
    this.setBusinessById(this.businessById.fetching());
    const result = businessesController.getBusinessesById(id);

    result
      .then((response) => {
        this.setBusinessById(this.businessById.fetched(response));
      })
      .catch((error) => {
        this.setBusinessById(this.businessById.failed(error.message));
      });

    return result;
  }

  public async getBusinessForFilter(params: GetBusinessRequest): Promise<void> {
    this.setBusinesses(this.businesses.fetching());
    const data = await businessesController.getBusinessesByParams(params);
    this.setBusinesses(this.businesses.fetched(data));
  }

  public async addBusiness(data: Business): Promise<Business> {
    const response = businessesController.addBusiness(data);
    response
      .then(() => {
        Toaster.success({ message: T.BUSINESS_CREATED_SUCCESSFULLY });
      })
      .catch((error) => {
        Toaster.error({ message: error.errorMessage });
      });

    return response;
  }

  public async deactivateBusiness(id: number) {
    return await businessesController.deactivateBusiness(id);
  }

  public async activateBusiness(id: number) {
    return await businessesController.activateBusiness(id);
  }

  public resetBusinessById() {
    this.setBusinessById(this.businessById.fetched({}));
  }
}

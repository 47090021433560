import React from "react";

import { Icons } from "../../../../../../../enums";
import { Icon } from "../../../../icon";

interface PaginationArrowProps {
  icon: Icons;
  disabled: boolean;
  onClick: () => void;
}

export const PaginationArrow: React.FC<PaginationArrowProps> = ({
  icon,
  onClick,
  disabled,
}) => {
  return (
    <div
      onClick={onClick}
      className={`table-pagination-arrow ${disabled ? "disabled" : ""}`}
    >
      <Icon type={icon} size="xs" />
    </div>
  );
};

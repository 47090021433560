import React from "react";

interface PaginationPageProps {
  active: boolean;
  disabled: boolean;
  onClick: () => void;
  page: number | string;
}

export const PaginationPage: React.FC<PaginationPageProps> = ({
  page,
  active,
  onClick,
  disabled,
}) => {
  return (
    <div
      onClick={onClick}
      className={`table-pagination-list-item ${active ? "active" : ""} ${disabled ? "disabled" : ""}`}
    >
      <p className="table-pagination-list-item-txt">{page}</p>
    </div>
  );
};

import { DropdownOptionModel } from "../view/components/shared/checkbox-dropdown/models";

export const filterData = (filteredValue: string, data: Array<any>) => {
  const lowerCaseSearchValue = filteredValue.toLowerCase();
  return data.filter(({ value, label }) => {
    return (
      `${value}`.toLowerCase().includes(lowerCaseSearchValue) ||
      `${label}`.toLowerCase().includes(lowerCaseSearchValue)
    );
  });
};

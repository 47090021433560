import { Icons } from "../../../../enums";
import { Section } from "../section";
import { Icon } from "../icon";

import "./style.scss";
import { NavLink } from "react-router-dom";
import { useTranslation } from "../../../../hooks";
import { T } from "../../../../constants";

interface LinkWidgetProps {
  icon: Icons;
  title: string;
  disableButton: boolean;
  iconColorHex?: string;
  onlineCount?: number;
  value: React.ReactNode;
  path: string;
}

export const LinkWidget: React.FC<LinkWidgetProps> = ({
  icon,
  value,
  title,
  iconColorHex,
  path,
  disableButton,
  onlineCount,
}) => {
  const t = useTranslation();

  return (
    <Section
      className={`dashboard-top-block-items ${disableButton ? "disabled" : ""}`}
    >
      <Icon wrap size="md" type={icon} colorHex={iconColorHex} />
      <NavLink className="partners-text-number-block" to={path}>
        <p className="partners-text">{title}</p>
        <p className="partners-number">{value}</p>
      </NavLink>
      {onlineCount ? (
        <div className="info-item-wrp">
          <div
            title=""
            style={{
              color: "rgb(76, 175, 80)",
              background: "rgba(76, 175, 80, 0.08)",
              borderColor: "rgba(76, 175, 80, 0.3)",
            }}
            className="status"
          >
            <p className="status-text">{`${onlineCount} ${t(T.ONLINE)}`}</p>
          </div>
        </div>
      ) : null}

      {/* TODO: This icon may function as a link button in the future */}
      <div className="dashboard-block-items-right">
        <i className="icon-arrow-right" />
      </div>
    </Section>
  );
};

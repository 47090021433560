import React, { createElement, ReactElement } from "react";

export const defineGuards = (
  layers: Array<React.FC<any>>,
  component: React.FC,
  props: React.Attributes | null | undefined = {},
): ReactElement => {
  const Component = component;
  if (!layers.length) {
    return <Component />;
  }
  return createElement(
    layers[0],
    props,
    defineGuards(layers.slice(1), Component, props),
  );
};

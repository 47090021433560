import { action, makeObservable, observable } from "mobx";

import {
  AddProviderData,
  GetProvidersRequest,
  Provider,
  ProvidersResponse,
} from "../models";
import { HttpResponse, providersController } from "../api";
import { DEFAULT_RESPONSE_LIST } from "../constants";
import { RootStore } from "./index";

export class ProvidersStore {
  private rootStore: RootStore;

  public providers = new HttpResponse<ProvidersResponse>(DEFAULT_RESPONSE_LIST);

  public providerById = new HttpResponse<Partial<Provider>>({});

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      providers: observable,
      providerById: observable,
      setProviders: action.bound,
      setProviderById: action.bound,
    });
  }

  public setProviders(value: HttpResponse<ProvidersResponse>): void {
    this.providers = value;
  }

  public setProviderById(value: HttpResponse<Partial<Provider>>): void {
    this.providerById = value;
  }

  public async getProviders(params: GetProvidersRequest): Promise<void> {
    this.setProviders(this.providers.fetching());
    const data = await providersController.getProviders(params);
    this.setProviders(this.providers.fetched(data));
  }

  public async getProviderById(id: number): Promise<void> {
    this.setProviderById(this.providerById.fetching());
    const data = await providersController.getProviderById(id);
    this.setProviderById(this.providerById.fetched(data));
  }

  public async addProvider(data: AddProviderData): Promise<Provider> {
    return await providersController.addProvider(data);
  }
}

import noDataImg from "../../../../../../assets/images/no-data.png";
import { useTranslation } from "../../../../../../hooks";
import { T } from "../../../../../../constants";

interface TableEmptyProps {}

export const TableEmpty: React.FC<TableEmptyProps> = () => {
  const t = useTranslation();
  return (
    <div className="table-no-data">
      <img src={noDataImg} alt="" />
      <div className="table-no-data-txt">
        <p className="table-no-data-title">{t(T.NO_DATA_TO_DISPLAY)}</p>
      </div>
    </div>
  );
};

import { CurrenciesRequest, CurrenciesResponse } from "../../../models";
import { BaseApiService } from "../../base-api-service";
import { ApiVersions } from "../../../enums";
import { env } from "../../../environment";
import { http } from "../../../services";

class CurrenciesController extends BaseApiService {
  public async getCurrencies(
    params?: CurrenciesRequest,
  ): Promise<CurrenciesResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/currency`;
    return await http.get(url, { params });
  }
}

export const currenciesController = new CurrenciesController({
  url: env.httpUrl,
});

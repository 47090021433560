import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";

import { getObjectKeys } from "../../../../helpers/get-object-keys";
import { getCurrentTheme } from "../../../../helpers/get-current-theme";
import {
  dataToDropDownConverter,
  hasPermission,
  LocalStorageHelper,
} from "../../../../helpers";

import { useStore } from "../../../../hooks";

import {
  DropdownOptionModel,
  DropdownOptionValue,
} from "../../shared/dropdown/models";

import { ThemeReverseMap } from "../../../../constants/theme";

import UserWallet from "./user-wallet";
import { UserSettings } from "./user-settings";
import { CreatePopover } from "./create-popover";
import CreateBusiness from "./create-business";
import { Icons, UserType } from "../../../../enums";
import { LocalStorageKeys } from "../../../../constants";
import { Dropdown, Icon } from "../../shared";
import Permission from "../../shared/permission";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = observer(() => {
  const { currenciesStore, userSettingsStore, partnerStore } = useStore();
  const {
    partnerBusiness: { data },
    selectedBusiness,
  } = partnerStore;
  const {
    userSettings: {
      data: {
        userType,
        limit: userLimit,
        balance: userBalance,
        walletCurrencyCode,
        partnerId,
      },
    },
  } = userSettingsStore;
  const {
    userCurrency,
    currencies: {
      data: { items: currencies },
    },
  } = currenciesStore;
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [theme, setTheme] = useState(getCurrentTheme());

  useEffect(() => {
    setSelectedCurrency(userCurrency.name as string);
  }, [userCurrency]);

  // TODO need do global for all using sections
  const currenciesDropdownData = useMemo<Array<DropdownOptionModel>>(() => {
    return dataToDropDownConverter(currencies, {
      valueKey: "name",
      labelKey: "name",
    });
  }, [currencies]);

  const handleCurrencyChange = useCallback(
    (value?: DropdownOptionValue | Array<DropdownOptionValue>) => {
      userSettingsStore.editUserSettings({ currencyCode: value as string });
    },
    [],
  );

  const changeTheme = () => {
    const newTheme = ThemeReverseMap[theme];
    setTheme(newTheme);
    LocalStorageHelper.setItem(LocalStorageKeys.ViewMode, newTheme);
    document.documentElement.setAttribute("class", newTheme);
  };

  const balance = useMemo(() => {
    return userType === UserType.Partner
      ? selectedBusiness?.balance
      : userBalance;
  }, [userBalance, selectedBusiness]);

  const limit = useMemo(() => {
    return userType === UserType.Partner ? selectedBusiness?.limit : userLimit;
  }, [userLimit, selectedBusiness]);

  const currency = useMemo(() => {
    return userType === UserType.Partner
      ? selectedBusiness?.currencyCode
      : walletCurrencyCode;
  }, [userLimit, selectedBusiness]);

  return (
    <div className="header">
      {/*TODO Multi currency*/}
      {hasPermission(userType as UserType, [UserType.Partner]) && data ? (
        <CreateBusiness
          businessData={data}
          currenciesDropdownData={currenciesDropdownData}
          partnerId={partnerId}
        />
      ) : null}
      <CreatePopover
        disableBTN={
          !getObjectKeys(selectedBusiness).length &&
          hasPermission(userType as UserType, [UserType.Partner])
        }
      />

      <Permission accessedUserTypes={[UserType.SuperAdmin, UserType.Admin]}>
        <Dropdown
          withSearch
          value={selectedCurrency}
          className="header-dropdown"
          data={currenciesDropdownData}
          onChange={handleCurrencyChange}
          isDrawSelectedItem={false}
        />
      </Permission>

      {
        <Permission accessedUserTypes={[UserType.Partner, UserType.Manager]}>
          <div className="balance-limit-info">
            <UserWallet
              amount={balance || 0}
              currency={currency}
              iconType={Icons.Balance}
              isBalance
              userType={userType}
            />
            <UserWallet
              amount={limit || 0}
              currency={currency}
              iconType={Icons.Block}
              userType={userType}
            />
          </div>
        </Permission>
      }

      {/*TODO light dark mode*/}
      <div className="light-dark-mode-container" onClick={changeTheme}>
        <Icon
          type={theme === "dark" ? Icons.DarkMode : Icons.LightMode}
          wrap
          size="md"
        />
      </div>
      <UserSettings />
    </div>
  );
});

export default memo(Header);

import { useState } from "react";
import { observer } from "mobx-react-lite";

import { Button, Popover, PopoverOption } from "../../../shared";
import { useStore, useTranslation } from "../../../../../hooks";
import { Icons } from "../../../../../enums";
import { T } from "../../../../../constants";
import { Languages } from "./languages";

import "./style.scss";

interface UserSettingsProps {}

export const UserSettings: React.FC<UserSettingsProps> = observer(() => {
  const { authStore, userSettingsStore } = useStore();
  const {
    userSettings: { data: userInfo },
  } = userSettingsStore;

  const t = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <div className="user-settings">
      <Popover
        isOpen={open}
        align="right"
        setIsOpen={setOpen}
        className="user-settings-popover"
        opener={
          <Button
            className="user-settings-btn"
            appearance="secondary"
            icon={Icons.ArrowDown}
            preIcon={Icons.Account}
            text={userInfo.userName}
          />
        }
      >
        <Languages onClose={() => setOpen(false)} />
        <PopoverOption
          icon={Icons.Logout}
          className="sign-out"
          label={t(T.SIGN_OUT)}
          onClick={() => authStore.logout()}
        />
      </Popover>
    </div>
  );
});

import React, { memo, useEffect, useState } from "react";

import { TableColumn, TableRowAction } from "../../models";
import { TableCell } from "../table-cell";
import TableRow from "../table-row";
import { generateKey } from "../../../../../../helpers/generate-unique-key";

interface TableBodyProps {
  rowKey?: string;
  columns: Array<TableColumn>;
  rows: Array<Record<string, any>>;
  totals?: Record<string, React.ReactNode>;
  onRowClick: (row: any, index: number) => void;
  getNestedTableData?: (row: any, index: number) => void;
  getRowActions?: (row: any, index: number) => Array<TableRowAction>;
}

const TableBody: React.FC<TableBodyProps> = ({
  rows,
  totals,
  columns,
  onRowClick,
  rowKey = "id",
  getRowActions,
}) => {
  const [openRow, setOpenRow] = useState(0);

  return (
    <div className="table-bottom">
      <div className="table-info">
        {/*{totals ? (*/}
        {/*  <TableRow key="totals">*/}
        {/*    {columns.map((col) => (*/}
        {/*      <TableCell key={col.dataKey}>TOTAL</TableCell>*/}
        {/*    ))}*/}
        {/*  </TableRow>*/}
        {/*) : null}*/}
        {rows?.map((row, index) => {
          return (
            <TableRow
              id={row.id}
              key={generateKey(row[rowKey])}
              actions={getRowActions?.(row, index)} // TODO: optimize if needed
              onClick={() => onRowClick(row, index)}
              setOpenRow={() => setOpenRow(row.id === openRow ? 0 : row.id)}
            >
              {columns?.map((col) => {
                return col.hideRow ? null : (
                  <TableCell key={col.dataKey}>
                    {col.colRenderer?.(
                      col.formatter?.(row[col.dataKey]) || row[col.dataKey],
                      row,
                      index,
                    ) || (
                      <p className="table-txt">
                        {col.formatter?.(row[col.dataKey]) ||
                          `${row[col.dataKey] ?? "-"}`}
                      </p>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </div>
    </div>
  );
};

export default memo(TableBody);

export class TypeChecker {
  static isNumber(value: any): boolean {
    return typeof value === "number";
  }

  static isEmptyString(val: any): boolean {
    return val === "";
  }

  static isString(val: any): boolean {
    return typeof val === "string";
  }

  static isArray(val: any): boolean {
    return Array.isArray(val);
  }

  static isNull(val: any): boolean {
    return val === null;
  }

  static isUndefined(val: any): boolean {
    return val === undefined;
  }
}

import { BaseApiConfig } from "../models";
import { http } from "../services";

export class BaseApiService {
  protected readonly url: string;
  protected http = http;

  constructor(config: BaseApiConfig) {
    this.url = config.url;
  }
}

import React, { useCallback, useEffect, useState } from "react";

import { AlertNotification, Notification } from "../../../models";
import { NotificationList } from "../../components/shared";
import { NotificationTypes } from "../../../enums";
import { ToasterConfig } from "./config";
import { guid } from "../../../helpers";

interface ToasterProps {}

interface ToasterType extends React.FC<ToasterProps> {
  success: (note: AlertNotification) => void;
  warning: (note: AlertNotification) => void;
  error: (note: AlertNotification) => void;
  info: (note: AlertNotification) => void;
  removeAll: () => void;
}

export const Toaster: ToasterType = () => {
  const [notifications, setNotifications] = useState<Array<Notification>>([]);

  useEffect(() => {
    Toaster.success = generateToasterAction(NotificationTypes.Success);
    Toaster.warning = generateToasterAction(NotificationTypes.Warning);
    Toaster.error = generateToasterAction(NotificationTypes.Error);
    Toaster.info = generateToasterAction(NotificationTypes.Info);
    Toaster.removeAll = (): void => setNotifications([]);
  }, []);

  const remove = useCallback((selectedNoteId: string) => {
    setNotifications((prev) => prev.filter(({ id }) => id !== selectedNoteId));
  }, []);

  const removeAfterTimeout = useCallback(
    (selectedNoteId: string, duration = 4000) => {
      const timeoutId = setTimeout(() => {
        remove(selectedNoteId);
        clearTimeout(timeoutId);
      }, duration);
    },
    [remove],
  );

  const alert = useCallback(
    (note: Notification) => {
      const { id, duration } = note;
      removeAfterTimeout(id, duration);
      setNotifications((prev) => [...prev, note]);
    },
    [setNotifications, removeAfterTimeout],
  );

  const generateToasterAction = useCallback(
    (type: NotificationTypes) => {
      return (note: AlertNotification): void => {
        alert({
          ...note,
          type,
          id: guid(),
          colorHex: ToasterConfig[type].colorHex,
          icon: note.icon || ToasterConfig[type].defaultIcon,
          duration: note.duration || ToasterConfig[type].defaultDuration,
        });
      };
    },
    [alert],
  );

  return <NotificationList onClose={remove} data={notifications} />;
};

// for typechecking
Toaster.success = () => {};
Toaster.warning = () => {};
Toaster.error = () => {};
Toaster.info = () => {};
Toaster.removeAll = () => {};

// setTimeout(() => {
//   // example of usage
//   Toaster.error({ message: 'error message' });
//   Toaster.success({ message: 'success message', icon: Icons.Check, duration: 5000 });
// }, 2000);

import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";

import { convertByCurrency } from "../../../../helpers";
import { useStore } from "../../../../hooks";

interface CurrencyProps {
  value?: number; // amount by usd
  convert?: boolean;
  currencyCode?: string;
}

export const Currency: React.FC<CurrencyProps> = observer(
  ({ value, currencyCode, convert }) => {
    const {
      currenciesStore: { userCurrency, currenciesMap },
    } = useStore();

    // TODO NEED CHANGE
    const { name, symbol, rate = 1 } = userCurrency;

    const symbolOrName = useMemo(() => {
      if (currencyCode) {
        const currency = currenciesMap[currencyCode];

        return currency?.symbol || currency?.name;
      }
      // return symbol || name;
    }, [currenciesMap, currencyCode, symbol, name]);

    if (!value && value !== 0) return <>-</>;

    const amount = `${symbolOrName || ""} ${convertByCurrency(value, convert ? rate : 1)} `;

    return <span title={amount}>{amount}</span>;
  },
);

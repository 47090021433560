import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";

import {
  useStore,
  useTranslation,
  useUpdateEffect,
} from "../../../../../hooks";
import { dataToDropDownConverter } from "../../../../../helpers";
import { DropdownOptionValue } from "../../dropdown/models";
import { T } from "../../../../../constants";
import { Dropdown } from "../../dropdown";

interface CashierFilterProps {
  value?: DropdownOptionValue;
  onChange?: (value?: DropdownOptionValue | Array<DropdownOptionValue>) => void;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, any>>>;
}

export const CashierFilter: React.FC<CashierFilterProps> = observer(
  ({ value, onChange, setFilters }) => {
    const t = useTranslation();
    const { usersStore } = useStore();
    const {
      cashiersForFilter: { data: cashiers },
    } = usersStore;

    useUpdateEffect(() => {
      if (value) {
        const cashier = cashiers.find((item) => item.id === +value);
        if (!cashier) {
          setFilters((prev) => {
            const { cashierId, ...restFilters } = prev;
            return restFilters;
          });
        }
      }
    }, [cashiers]);

    const cashiersData = useMemo(() => {
      return dataToDropDownConverter(cashiers, {
        labelKey: "userName",
        valueKey: "id",
      });
    }, [cashiers]);

    return (
      <Dropdown
        hasAll
        value={value}
        onChange={onChange}
        data={cashiersData}
        label={t(T.CASHIER)}
        withSearch={!!cashiersData.length}
      />
    );
  },
);

import { action, makeObservable, observable } from "mobx";

import {
  GetTicketReportRequest,
  GetUserReportRequest,
  TicketReportResponse,
  UserReportResponse,
} from "../models";
import { HttpResponse, reportsController } from "../api";
import { RootStore } from "./index";

export class ReportsStore {
  rootStore: RootStore;

  public userReport = new HttpResponse<Partial<UserReportResponse>>({});

  public ticketReport = new HttpResponse<Partial<TicketReportResponse>>({});

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      userReport: observable,
      ticketReport: observable,
      setUserReport: action.bound,
      setTicketReport: action.bound,
      resetAll: action.bound,
    });
  }

  public setUserReport(value: HttpResponse<Partial<UserReportResponse>>): void {
    this.userReport = value;
  }

  public setTicketReport(
    value: HttpResponse<Partial<TicketReportResponse>>,
  ): void {
    this.ticketReport = value;
  }

  public resetAll(): void {
    this.setUserReport(this.userReport.fetching());
    this.setTicketReport(this.ticketReport.fetching());
  }

  public removeAllData(): void {
    this.setUserReport(new HttpResponse<Partial<UserReportResponse>>({}));
    this.setTicketReport(new HttpResponse<Partial<TicketReportResponse>>({}));
  }

  public async getUserReport(params?: GetUserReportRequest): Promise<void> {
    this.setUserReport(this.userReport.fetching());
    const data = await reportsController.getUserReport(params);
    this.setUserReport(this.userReport.fetched(data));
  }

  public async getTicketReport(params?: GetTicketReportRequest): Promise<void> {
    this.setTicketReport(this.ticketReport.fetching());
    const data = await reportsController.getTicketReport(params);
    this.setTicketReport(this.ticketReport.fetched(data));
  }
}

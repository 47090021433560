import React from "react";

interface AddEditViewContentProps {
  children: React.ReactNode;
}

export const AddEditViewContent: React.FC<AddEditViewContentProps> = ({
  children,
}) => {
  return <div className="page-info-content">{children}</div>;
};

import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";

import { getMonthDays, utcToLocal } from "../../../../helpers";
import { DATE_FORMAT_BACK, RegexConstants } from "../../../../constants";
import { DatePickerDay } from "../../../../models";
import { Navigation } from "./navigation";
import { Weeks } from "./weeks";
import { Days } from "./days";

import "./styles.scss";
import { getHours, getMinute } from "../../../../helpers/data-picker-hours";
import { Simulate } from "react-dom/test-utils";
import focus = Simulate.focus;

interface CalendarProps {
  value: Date | string;
  selectedHours: string;
  selectedMinute: string;
  hideFutureDays?: boolean;
  onChange: (date: Date) => void;
  changeHours: (date: string) => void;
  setSelectedHours: (date: any) => void;
  setSelectedMinute: (date: any) => void;
}

export const Calendar: React.FC<CalendarProps> = ({
  changeHours,
  onChange,
  selectedHours,
  selectedMinute,
  setSelectedHours,
  setSelectedMinute,
  value = "",
  hideFutureDays,
}) => {
  const focusMinuteInputRef = useRef<HTMLInputElement>(null);
  const localDate = useMemo(() => {
    return value ? new Date(utcToLocal(value, DATE_FORMAT_BACK)) : "";
  }, [value]);

  const initialData = useMemo(() => {
    const selectedOrToday = localDate ? new Date(localDate) : new Date();
    const thisMonth = selectedOrToday.getMonth();
    const thisYear = selectedOrToday.getFullYear();
    return { thisMonth, thisYear };
  }, [localDate]);

  const [month, setMonth] = useState(initialData.thisMonth);
  const [year, setYear] = useState(initialData.thisYear);

  const handleChange = useCallback(
    (day: DatePickerDay) => {
      // if (day.disabled) return;
      onChange(day.date);
    },
    [onChange],
  );

  useEffect(() => {
    changeHours(`${selectedHours}:${selectedMinute}`);
  }, [selectedHours, selectedMinute]);

  const days = useMemo(() => {
    return getMonthDays(year, month);
  }, [month, year]);

  const onYearNavClick = useCallback((value: number) => {
    setYear((prev) => prev + value);
  }, []);

  const onMonthNavClick = useCallback(
    (value: number) => {
      const newMonth = (month + value + 12) % 12;
      setMonth(newMonth);
      if (
        (newMonth === 0 && month === 11) ||
        (newMonth === 11 && month === 0)
      ) {
        // year change
        setYear((prev) => prev + value);
      }
    },
    [month],
  );

  const handleTimeChange = (
    value: string,
    callback: React.SetStateAction<any>,
    type: string,
  ) => {
    if (!value) {
      callback(value);
    } else if (
      (value[0] === "0" || value[0] === "1" || value[0] === "2") &&
      type === "hours" &&
      RegexConstants.Hours.test(value)
    ) {
      callback(value);
      if (focusMinuteInputRef.current && value.length === 2) {
        focusMinuteInputRef.current.focus();
      }
    } else if (type === "minute" && RegexConstants.Minute.test(value)) {
      callback(value);
    }
  };

  const incrementOrDecrementHours = (action: string) => {
    setSelectedHours(getHours(selectedHours, action));
  };

  const incrementOrDecrementMinutes = (action: string) => {
    setSelectedMinute(getMinute(selectedMinute, action));
  };

  return (
    <div className="datepicker">
      <Navigation
        year={year}
        month={month}
        onYearNavClick={onYearNavClick}
        onMonthNavClick={onMonthNavClick}
      />
      <div className="calendar-days">
        <Weeks />
        <Days
          days={days}
          selectedDay={value}
          onDayClick={handleChange}
          hideFutureDays={hideFutureDays}
        />

        <div className="date-picker-hours">
          <div className="date-picker-hours-col">
            <button
              type="button"
              className="date-picker-btn increment-btn"
              onClick={() => incrementOrDecrementHours("plus")}
            >
              <i className="icon-arrow-right " />
            </button>
            <input
              type="text"
              className="date-picker-hours-input"
              value={selectedHours}
              onChange={(e) =>
                handleTimeChange(e.target.value, setSelectedHours, "hours")
              }
              placeholder="00"
            />
            <button
              type="button"
              className="date-picker-btn decrement-btn"
              onClick={() => incrementOrDecrementHours("minus")}
            >
              <i className="icon-arrow-right " />
            </button>
          </div>
          <div className="date-picker-hours-col">
            <button
              type="button"
              className="date-picker-btn increment-btn"
              onMouseDown={() => incrementOrDecrementMinutes("plus")}
            >
              <i className="icon-arrow-right " />
            </button>
            <input
              type="text"
              className="date-picker-hours-input"
              value={selectedMinute}
              onChange={(e) =>
                handleTimeChange(e.target.value, setSelectedMinute, "minute")
              }
              ref={focusMinuteInputRef}
              placeholder="00"
            />
            <button
              type="button"
              className="date-picker-btn decrement-btn"
              onClick={() => incrementOrDecrementMinutes("minus")}
            >
              <i className="icon-arrow-right " />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

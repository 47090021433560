import React from "react";

interface TableHeaderProps {
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
  leftActions,
  rightActions,
}) => {
  const showHeader = !!leftActions || !!rightActions;

  return showHeader ? (
    <div className="table-content-header">
      <div className="table-left-actions">{leftActions}</div>
      <div className="table-right-actions">{rightActions}</div>
    </div>
  ) : null;
};

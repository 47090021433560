import React, { useCallback } from "react";

import ErrorImage from "../../../../assets/images/image-not-found.png";

interface ImageProps {
  src: string;
  alt?: string;
  className?: string;
}

export const Image: React.FC<ImageProps> = ({ src, alt, className = "" }) => {
  const handleError = useCallback<React.ReactEventHandler<HTMLImageElement>>(
    (event) => {
      (event.target as HTMLImageElement).src = ErrorImage;
    },
    [],
  );

  return (
    <img src={src} alt={alt} className={className} onError={handleError} />
  );
};

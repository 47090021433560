import React from "react";

import { Icons } from "../../../../../enums";
import { Icon } from "../../icon";
import { useTranslation } from "../../../../../hooks";
import { T } from "../../../../../constants";

interface FilterToggleProps {
  toggle: () => void;
  isDisabled?: boolean;
}

export const FilterToggle: React.FC<FilterToggleProps> = ({
  toggle,
  isDisabled = false,
}) => {
  const t = useTranslation();
  return (
    <button
      type="button"
      onClick={toggle}
      className={`filters-btn ${isDisabled ? "disabled" : ""}`}
      disabled={isDisabled}
    >
      <Icon type={Icons.Filter} />
      <p className="filters-btn-txt">{t(T.FILTERS)}</p>
      {/* {
        !!usedFilters && (
          <span className="filters-amount">{usedFilters}</span>
        )
      } */}
    </button>
  );
};

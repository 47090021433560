import React from "react";

interface ModalContainerProps {}

export const MODAL_CONTAINER_ID = "modalContainer";

export const ModalContainer: React.FC<ModalContainerProps> = () => {
  return (
    // TODO: state top
    // add class "state-top"
    <div className="modal-container" id={MODAL_CONTAINER_ID} />
  );
};

import React from "react";

import { useTranslation } from "../../../../../hooks";
import { MONTHS } from "../../../../../constants";

interface NavigationProps {
  year: number;
  month: number;
  onYearNavClick: (step: -1 | 1) => void;
  onMonthNavClick: (step: -1 | 1) => void;
}

export const Navigation: React.FC<NavigationProps> = ({
  year,
  month,
  onYearNavClick,
  onMonthNavClick,
}) => {
  const t = useTranslation();

  return (
    <div className="date-heading">
      <div className="date-actions">
        <div className="date-action" onClick={() => onYearNavClick(-1)}>
          <i className="icon-double-arrow-left" />
        </div>
        <div className="date-action" onClick={() => onMonthNavClick(-1)}>
          <i className="icon-arrow-left" />
        </div>
      </div>
      <p className="date-month-year">
        {year} {t(MONTHS[month])}
      </p>
      <div className="date-actions">
        <div className="date-action" onClick={() => onMonthNavClick(1)}>
          <i className="icon-arrow-right" />
        </div>
        <div className="date-action" onClick={() => onYearNavClick(1)}>
          <i className="icon-double-arrow-right" />
        </div>
      </div>
    </div>
  );
};

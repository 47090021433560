import { BaseApiService } from "../../base-api-service";
import { ApiVersions } from "../../../enums";
import { env } from "../../../environment";
import { http } from "../../../services";
import { CountriesResponse } from "../../../models/api/countries";

class CountriesController extends BaseApiService {
  public async getCountries(): Promise<CountriesResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/country`;
    return await http.get(url);
  }
}

export const countriesController = new CountriesController({
  url: env.httpUrl,
});

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { parseQuery, combineQuery } from "../helpers";

export const useQuery = () => {
  const location = useLocation();
  const [query, setQuery] = useState<Record<string, any>>(
    parseQuery(location.search),
  );

  useEffect(() => {
    setQuery(parseQuery(location.search));
  }, [location.search]);

  return {
    queryString: combineQuery(query),
    query,
    pathname: location.pathname.split("/")[1],
  };
};

import React, { useCallback, useState } from "react";

import { useStore, useTranslation } from "../../../../hooks";
import { Button, Checkbox } from "../../../components/shared";
import { LoginRequestData } from "../../../../models";
import { T } from "../../../../constants";
import { Icons } from "../../../../enums";
import Login from "../index";
import Input from "../../../components/shared/input";
import { NavLink } from "react-router-dom";

interface LoginFormProps {}

export const LoginForm: React.FC<LoginFormProps> = () => {
  const { authStore } = useStore();
  const t = useTranslation();

  const [credentials, setCredentials] = useState<LoginRequestData>({
    username: "",
    password: "",
    rememberMe: false,
  });
  const [loading, setLoading] = useState(false);
  const [isShowPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCredentialsChange = useCallback(
    (value: string, field: keyof LoginRequestData) => {
      setErrorMessage("");
      setCredentials((prev) => ({
        ...prev,
        [field]: value,
      }));
    },
    [],
  );

  const loginDisabled =
    loading || !credentials.username || !credentials.password;

  const handleSubmit = useCallback(
    (event: React.FormEvent): void => {
      event.preventDefault();
      if (loginDisabled) return;
      setLoading(true);
      const { username, password } = credentials;
      authStore
        .login({
          username: username.trim(),
          password: password.trim(),
          rememberMe: credentials.rememberMe,
        })
        .then((data) => {
          authStore.setIsAuth(true);
        })
        .catch((error) => {
          setErrorMessage(error?.errorMessage);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [loginDisabled, credentials],
  );

  // {t(T.SIGN_IN_TITLE)}

  return (
    <Login>
      <form className="sign-in-form" onSubmit={handleSubmit}>
        {/*///new////*/}
        {/*<div className="email-sent-success-block">*/}
        {/*    <p className="email-sent-success-txt">Email Sent Successfully</p>*/}
        {/*	<div className="email-sent-success-icon-block">*/}
        {/*		<i className="icon-check-circle"/>*/}
        {/*	</div>*/}
        {/*</div>*/}
        {/*<p className="email-request-text">*/}
        {/*	Your request has been sent. Please check your email.*/}
        {/*</p>*/}
        {/*<p className="email-request-text">*/}
        {/*	If you don't receive an email within the next few minutes, please check your spam folder.*/}
        {/*</p>*/}
        {/*////new//////*/}
        <p className="sign-in-form-header">{t(T.SIGN_IN)}</p>
        <Input
          label={t(T.USERNAME)}
          className="sign-in-input"
          placeholder={t(T.USERNAME)}
          value={credentials.username}
          errorMessage={errorMessage}
          onChange={(e) => handleCredentialsChange(e.target.value, "username")}
        />
        <Input
          type={isShowPassword ? "text" : "password"}
          label={t(T.PASSWORD)}
          className="sign-in-input"
          placeholder={t(T.PASSWORD)}
          value={credentials.password}
          errorMessage={errorMessage}
          onIconClick={() => setShowPassword(!isShowPassword)}
          onChange={(e) => handleCredentialsChange(e.target.value, "password")}
          icon={
            credentials.password.length
              ? isShowPassword
                ? Icons.VisibilityOn
                : Icons.VisibilityOff
              : null
          }
        />
        <div className="checkbox-info-row">
          <div className="checkbox-holder">
            <div className="checkbox">
              <input
                type="checkbox"
                id="rememberMe"
                onChange={(e) =>
                  setCredentials({
                    ...credentials,
                    rememberMe: e.target.checked,
                  })
                }
              />
              <span className="checkbox-checkmark check" />
            </div>
            <label className="checkbox-label" htmlFor="rememberMe">
              {t(T.REMEMBER_ME)}
            </label>
          </div>
          {/*<NavLink className="sign-in-forgot-pass-text" to={"/forgot-password"}>*/}
          {/*  {t(T.FORGOT_PASSWORD)}?{" "}*/}
          {/*</NavLink>*/}
        </div>
        *
        <div className="sign-in-btn-wrp">
          <Button
            type="submit"
            disabled={loginDisabled}
            text={loading ? "Loading..." : t(T.SIGN_IN)}
          />
        </div>
      </form>
    </Login>
  );
};

import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";

import { useStore, useTranslation } from "../../../../../../hooks";
import { LocalStorageKeys, T } from "../../../../../../constants";
import { LocalStorageHelper } from "../../../../../../helpers";
import { Language } from "../../../../../../models";
import { PopoverOption } from "../../../../shared";
import { env } from "../../../../../../environment";

interface LanguagesProps {
  onClose: () => void;
}

export const Languages: React.FC<LanguagesProps> = observer(({ onClose }) => {
  const { translationsStore } = useStore();
  const {
    selectedLanguageCode,
    setSelectedLanguageCode,
    languages: {
      data: { items: languages },
    },
  } = translationsStore;

  const t = useTranslation();

  const handleLanguageClick = useCallback(
    (code: string) => {
      setSelectedLanguageCode(code);
      LocalStorageHelper.setItem(LocalStorageKeys.Language, code);
      translationsStore.getTranslations({ code });
      onClose();
    },
    [setSelectedLanguageCode],
  );

  const LANGUAGES_MAP = useMemo(() => {
    return languages.reduce<Record<string, Language>>((acc, curr) => {
      acc[curr.uid] = curr;
      return acc;
    }, {});
  }, [languages]);

  const selectedLanguage = useMemo(() => {
    return LANGUAGES_MAP[selectedLanguageCode];
  }, [LANGUAGES_MAP, selectedLanguageCode]);

  return (
    <PopoverOption
      label={selectedLanguage?.name}
      childrenTitle={t(T.LANGUAGES)}
      imageUrl={`${env.translationsHttpUrl}/${selectedLanguage?.flag}`}
    >
      {languages.map(({ flag, uid, name }) => {
        const requiredLanguages = [
          "english",
          "portuguese",
          "portuguesdobrazil",
          "romanian",
          "french",
          "spanish",
          "spanish (peru)",
        ];
        if (requiredLanguages.includes(name.toLowerCase())) {
          return (
            <PopoverOption
              key={uid}
              label={name}
              onClick={() => handleLanguageClick(uid)}
              imageUrl={`${env.translationsHttpUrl}/${flag}`}
            />
          );
        }
      })}
    </PopoverOption>
  );
});

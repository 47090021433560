import { DependencyList, EffectCallback, useEffect, useRef } from "react";

export const useUpdateEffect = (
  func: EffectCallback,
  dep: DependencyList,
): void => {
  const ref = useRef(false);

  useEffect(() => {
    if (ref.current) func();
    else ref.current = true;
  }, dep);
};

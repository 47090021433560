export enum Icons {
  SortArrowDown = 'icon-sort-single-arrow-down',
  SortArrowUp = 'icon-sort-single-arrow-up',
  CloseCircle = 'icon-close-circled',
  Cup = 'icon-cup',
  Moon = 'icon-moon',
  LightMode = 'icon-light',
  DarkMode = 'icon-dark-mode',
  ArrowDecorated = 'icon-arrow-decorated',
  ArrowLeft = 'icon-arrow-left',
  ArrowDown = 'icon-arrow-down',
  ArrowRight = 'icon-arrow-right',
  Barcode = 'icon-barcode',
  BetHistory = 'icon-bet-history',
  BetAmountHistory ='icon-history',
  TransactionHistory = 'icon-transaction',
  Betshop = 'icon-betshop',
  Calendar = 'icon-calendar',
  Users = 'icon-cashier',
  GamesControl= 'icon-games-control',
  Check = 'icon-check',
  Close = 'icon-close',
  Dashboard = 'icon-dashboard',
  Description = 'icon-description',
  ArrowLeftDouble = 'icon-double-arrow-left',
  ArrowRightDouble = 'icon-double-arrow-right',
  Edit = 'icon-edit',
  Export = 'icon-export',
  Filter = 'icon-filter-list',
  GGRRatio = 'icon-ggr-ratio',
  GGR = 'icon-ggr',
  InfoCircle = 'icon-info',
  Integration = 'icon-integration',
  Block = 'icon-limit',
  Balance = 'icon-balance',
  Lock = 'icon-lock',
  Logout = 'icon-logout',
  Manager = 'icon-manager',
  Minus = 'icon-minus',
  Partnership = 'icon-partnership',
  Plus = 'icon-plus',
  Refresh = 'icon-refresh',
  Search = 'icon-search',
  Settings = 'icon-settings',
  SortArrowsDouble = 'icon-sort-arrows',
  Unlock = 'icon-unlock',
  VisibilityOff = 'icon-visibility-off',
  VisibilityOn = 'icon-visibility',
  Warning = 'icon-warning',
  Account = 'icon-user',
  Back = 'icon-arrow-back-2',
  Key = 'icon-key',
  EditBusiness = 'icon-business',
  BusinessSettings = 'icon-business2',
  Password = 'icon-change-pass',
  Configurations= 'icon-configuration',
  Reports= 'icon-reports'
}

import {
  AddBetshopData,
  Betshop,
  BetshopsResponse,
  EditBetshopData,
  GetBetshopsRequest,
} from "../../../models";
import { BaseApiService } from "../../base-api-service";
import { ApiVersions } from "../../../enums";
import { env } from "../../../environment";

class BetshopsController extends BaseApiService {
  public async getBetshops(
    params?: GetBetshopsRequest,
  ): Promise<BetshopsResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/betshop`;
    return await this.http.get(url, { params });
  }

  public async getBetshopById(id: number): Promise<Betshop> {
    const url = `${this.url}/api/${ApiVersions.V1}/betshop/${id}`;
    return await this.http.get(url);
  }

  public async editBetshop(id: number, data: EditBetshopData): Promise<void> {
    const url = `${this.url}/api/${ApiVersions.V1}/betshop/${id}`;
    return await this.http.put(url, data);
  }

  public async addBetshop(data: AddBetshopData): Promise<Betshop> {
    const url = `${this.url}/api/${ApiVersions.V1}/betshop`;
    return await this.http.post(url, data);
  }

  public async deactivateBetshop(id: number): Promise<void> {
    const url = `${this.url}/api/${ApiVersions.V1}/betshop/deactivate/${id}`;
    return await this.http.put(url);
  }

  public async activateBetshop(id: number): Promise<void> {
    const url = `${this.url}/api/${ApiVersions.V1}/betshop/activate/${id}`;
    return await this.http.put(url);
  }
}

export const betshopsController = new BetshopsController({ url: env.httpUrl });

import { Icons, NotificationTypes } from "../../../enums";

// toaster default duration in milliseconds
const defaultDuration = 4000;

export const ToasterConfig = {
  [NotificationTypes.Success]: {
    colorHex: "#4caf50",
    defaultIcon: Icons.Check,
    defaultDuration,
  },
  [NotificationTypes.Warning]: {
    colorHex: "#ff8c00",
    defaultIcon: Icons.Warning,
    defaultDuration,
  },
  [NotificationTypes.Error]: {
    colorHex: "#e84855",
    defaultIcon: Icons.Block,
    defaultDuration,
  },
  [NotificationTypes.Info]: {
    colorHex: "#007fdb",
    defaultIcon: Icons.InfoCircle,
    defaultDuration,
  },
};

import React from "react";

interface AddEditViewHeaderProps {
  children: React.ReactNode;
}

export const AddEditViewHeader: React.FC<AddEditViewHeaderProps> = ({
  children,
}) => {
  return <div className="page-info-header">{children}</div>;
};

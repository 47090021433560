import React, { memo } from "react";
import { observer } from "mobx-react-lite";

import { hasPermission } from "../../../../helpers";
import NotFound from "../../../pages/not-found";
import { UserType } from "../../../../enums";
import { useStore } from "../../../../hooks";

interface PermissionProps {
  isPage?: number;
  children: React.ReactNode;
  // if `accessedUserTypes` not provided in props then every user type can access to `children`
  accessedUserTypes?: Array<UserType>;
}

const Permission: React.FC<PermissionProps> = observer(
  ({ children, isPage = false, accessedUserTypes = [] }) => {
    const {
      userSettingsStore: {
        userSettings: {
          data: { userType },
        },
      },
    } = useStore();

    if (hasPermission(userType as UserType, accessedUserTypes))
      return <>{children}</>;

    return isPage ? <NotFound /> : null;
  },
);

export default memo(Permission);

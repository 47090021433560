import React from "react";

import "./style.scss";

interface SectionProps {
  className?: string;
  title?: React.ReactNode;
  children: React.ReactNode;
  headerValue?: React.ReactNode;
}

export const Section: React.FC<SectionProps> = ({
  title,
  children,
  headerValue,
  className = "",
}) => {
  return (
    <div className={`section ${className}`}>
      {(title || headerValue) && (
        <div className="section-header">
          <h3 className="header-title">{title}</h3>
          <div className="header-value">{headerValue}</div>
        </div>
      )}
      {children}
    </div>
  );
};

import { JSONHelper } from "../helpers";

export class LocalStorageHelper {
  static getItem(key: string): any {
    return JSONHelper.parse(`${localStorage.getItem(key)}`);
  }

  static setItem(key: string, value: any): void {
    localStorage.setItem(key, JSONHelper.stringify(value));
  }

  static removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  static clearAll(): void {
    localStorage.clear();
  }
}

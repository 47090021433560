import React, { useMemo, useRef } from "react";

import { guid } from "../../../../helpers";

import "./style.scss";

interface CheckboxProps {
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  onChange,
  label = "",
  indeterminate,
  checked = false,
  disabled,
}) => {
  const inputId = useRef(guid());

  const state = useMemo(() => {
    if (checked) return "check";
    if (indeterminate) return "minus";
    return "";
  }, [checked, indeterminate]);

  return (
    <div className="checkbox-holder">
      <div className="checkbox">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          id={inputId.current}
          disabled={disabled}
        />
        <span className={`checkbox-checkmark ${state}`} />
      </div>
      <label className="checkbox-label" htmlFor={inputId.current}>
        {label}
      </label>
    </div>
  );
};

import { BaseApiService } from "../../base-api-service";
import { ApiVersions } from "../../../enums";
import { env } from "../../../environment";
import {
  ConfigurationsRequest,
  ConfigurationsUpdateData,
} from "../../../models/api/configurations";
import { httpForFormData } from "../../../services";
import { TicketViewState } from "../../../models";

class ConfigurationsController extends BaseApiService {
  public async getConfigurations(
    params?: Object,
  ): Promise<ConfigurationsRequest> {
    const url = `${this.url}/api/${ApiVersions.V1}/ticketConfig`;
    return await this.http.get(url, { params });
  }

  public async updateConfigurations(
    data: TicketViewState,
  ): Promise<ConfigurationsUpdateData> {
    const url = `${this.url}/api/${ApiVersions.V1}/ticketConfig`;
    return await httpForFormData.put(url, data);
  }
}

export const configurationsController = new ConfigurationsController({
  url: env.httpUrl,
});

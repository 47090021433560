import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { RoutePaths } from "../../constants";

interface MainRedirectProps {}

const MainRedirect: React.FC<MainRedirectProps> = () => {
  const { search } = useLocation();

  return <Navigate to={`${RoutePaths.Dashboard}${search}`} />;
};

export default MainRedirect;

import { DropdownOptionModel } from "../view/components/shared/dropdown/models";

interface OptionalParams {
  labelKey?: string;
  valueKey?: string;
  translate?: (key: string) => string;
}

export const dataToDropDownConverter = (
  data: Array<Record<string, any>>,
  options?: OptionalParams,
): Array<DropdownOptionModel> => {
  const labelKey = options?.labelKey || "label";
  const valueKey = options?.valueKey || "value";

  const translate = options?.translate;

  return data.map((data) => ({
    ...data,
    disabled: data.disabled,
    value: data[valueKey],
    label: translate ? translate(data[labelKey]) : data[labelKey],
  }));
};

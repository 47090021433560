import { useEffect } from "react";

export const useOutsideClick = <T extends HTMLElement>(
  handler: () => void,
  ref: React.RefObject<T>,
  openerRef?: React.RefObject<T>,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent): void => {
      const target = event.target as HTMLElement;

      if (
        event.target &&
        target.closest(".nested-dropdown-menu-option-title")
      ) {
        // when click prev btn in nested table
        return;
      }

      if (
        !ref.current ||
        ref.current.contains(event.target as Node) ||
        openerRef?.current?.contains(event.target as Node)
      ) {
        return;
      }
      handler();
    };

    const onBlurListener = (): void => {
      // check if  activeElement is iframe element
      if (document.activeElement?.tagName === "IFRAME") {
        handler();
      }
    };

    document.addEventListener("click", listener);
    window.addEventListener("blur", onBlurListener);

    return () => {
      document.removeEventListener("click", listener);
      window.removeEventListener("blur", onBlurListener);
    };
  }, [ref, openerRef, handler]);
};

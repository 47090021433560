import { memo } from "react";

import { TableRowAction } from "../../models";
import { Icon } from "../../../icon";

interface TableRowProps {
  onClick?: () => void;
  children: React.ReactNode;
  actions?: Array<TableRowAction>;
  id?: number;
  setOpenRow?: () => void;
}

const TableRow: React.FC<TableRowProps> = ({ onClick, actions, children }) => {
  return (
    <div className="table-row" onClick={onClick}>
      <div className="table-row-inner">
        <div className="table-row-cells">
          {/*<div className="icon-arrow-wrp">*/}
          {/*	<i className="icon-arrow-right"></i>*/}
          {/*</div>*/}
          {children}
        </div>
        {actions?.length ? (
          <div className="table-row-actions-panel">
            {actions.map(({ icon, onClick }) => (
              <Icon
                key={icon}
                type={icon}
                onClick={onClick}
                className="table-row-action"
              />
            ))}
          </div>
        ) : null}
      </div>
      <div className="table-nested">
        {/*ToDo: add class open on "table-nested-inner" element */}
        <div className="table-nested-inner">
          <div className="table-row header-row">
            <div className="table-row-cells">
              <div className="icon-arrow-wrp"></div>
              <div className="table-col">
                <p className="table-txt">Game Name</p>
              </div>
              <div className="table-col">
                <p className="table-txt">Round ID</p>
              </div>
              <div className="table-col">
                <p className="table-txt">Selection Name</p>
              </div>
              <div className="table-col">
                <p className="table-txt">Bet Amount</p>
              </div>
              <div className="table-col">
                <p className="table-txt">Possible Win</p>
              </div>
            </div>
          </div>
          <div className="table-info">
            <div className="table-row">
              <div className="table-row-cells">
                <div className="icon-arrow-wrp"></div>
                <div className="table-col">
                  <p className="table-txt">1500</p>
                </div>
                <div className="table-col">
                  <p className="table-txt">240</p>
                </div>
                <div className="table-col">
                  <p className="table-txt">240</p>
                </div>
                <div className="table-col">
                  <p className="table-txt">240</p>
                </div>
                <div className="table-col">
                  <p className="table-txt">240</p>
                </div>
              </div>
            </div>
            <div className="table-row">
              <div className="table-row-cells">
                <div className="icon-arrow-wrp"></div>
                <div className="table-col">
                  <p className="table-txt">1500</p>
                </div>
                <div className="table-col">
                  <p className="table-txt">240</p>
                </div>
                <div className="table-col">
                  <p className="table-txt">240</p>
                </div>
                <div className="table-col">
                  <p className="table-txt">240</p>
                </div>
                <div className="table-col">
                  <p className="table-txt">240</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TableRow);

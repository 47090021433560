import { makeObservable, observable, action } from "mobx";

import { LocalStorageHelper } from "../helpers";
import { LoginRequestData, LoginResponse } from "../models";
import { LocalStorageKeys } from "../constants";
import { authController, betshopsController, usersController } from "../api";
import { rootStore, RootStore } from ".";
import { useStore } from "../hooks";

export class AuthStore {
  private rootStore?: RootStore;

  private readonly expirationInsurance = 100;
  private refreshTokenTimer?: number;

  public access: LoginResponse | null = null;
  public isAuth: boolean = true;

  constructor(rootStore?: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      isAuth: observable,
      access: observable,
      setIsAuth: action.bound,
      setAccess: action.bound,
    });
  }

  public setIsAuth(value: boolean): void {
    this.isAuth = value;
  }

  public setAccess(value: LoginResponse | null): void {
    this.access = value;
  }

  private setAccessToLocalStorage(access: LoginResponse): void {
    const data = {
      ...access,
      expiresIn: (access.expiresIn - this.expirationInsurance) * 1000 + Date.now(),
    };
    LocalStorageHelper.setItem(LocalStorageKeys.Access, data);
  }

  private handleTokenArrival(access: LoginResponse): void {
    this.setAccessToLocalStorage(access);
    this.setAccess(access);
    clearTimeout(this.refreshTokenTimer);
    const timeout = (access.expiresIn - this.expirationInsurance) * 1000;
    this.refreshTokenTimer = window.setTimeout(() => {
      authController.refresh()
        .then(res => res.json())
        .then((res) => {
          this.handleTokenArrival(res.result);
        }).catch(async () => {
          await this.logout();
      })
    }, timeout);
  }

  public async login(data: LoginRequestData): Promise<LoginResponse> {
    const response = await authController.login(data);
    // localStorage.setItem('accessToken', response.accessToken);
    this.handleTokenArrival(response);
    return response;
  }

  public async refresh(): Promise<LoginResponse> {
    const response = await authController.refresh();
    const { result } = await response.json();
      if (result) {
        this.handleTokenArrival(result);
      } else {
        this.logout();
      }
      return result;
  }

  public async logout(): Promise<void> {
    this.setIsAuth(false);
    this.setAccess(null);
    LocalStorageHelper.removeItem(LocalStorageKeys.Access);
    rootStore.userSettingsStore.resetUserSettings();
    rootStore.reportsStore.removeAllData();
    rootStore.partnerStore.resetSelectedBusiness();
    clearTimeout(this.refreshTokenTimer);
    await authController.logout();
  }
}

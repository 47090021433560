import React from "react";

import { BreadcrumbItem } from "./breadcrumb-item";
import { BreadcrumbItemData } from "./models";

import "./style.scss";

interface BreadcrumbProps {
  items: Array<BreadcrumbItemData>;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <div className="breadcrumbs">
      {items.map(({ name, active, onClick }, index) => (
        <BreadcrumbItem
          key={name}
          name={name}
          active={active}
          onClick={onClick}
          showArrow={index !== 0}
        />
      ))}
    </div>
  );
};

import notFoundImg from "../../../assets/images/not-found.png";
import { useTranslation } from "../../../hooks";
import { RoutePaths, T } from "../../../constants";
import { NavLink } from "react-router-dom";
interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = () => {
  const t = useTranslation();

  return (
    <div className="not-found-page">
      <div className="not-found-info-wrp">
        <span className="not-found-info">{t(T.ERROR)}</span>
        <div className="not-found-img-block">
          <img src={notFoundImg} alt="" />
        </div>
        <span className="not-found-info">{t(T.PAGE_NOT_FOUND)}</span>
      </div>
      <NavLink to={RoutePaths.Dashboard} key={RoutePaths.Dashboard}>
        <button type="button" className="back-to-btn">
          <span className="back-to-btn-txt">{t(T.BACK_TO_DASHBOARD)}</span>
        </button>
      </NavLink>
    </div>
  );
};

export default NotFound;

import React, { useCallback } from "react";

import { DatePickerDay } from "../../../../../models";
import { log } from "util";
import moment from "moment";

interface DaysProps {
  selectedDay: Date | string;
  hideFutureDays?: boolean;
  days: Array<DatePickerDay>;
  onDayClick: (day: DatePickerDay) => void;
}

export const Days: React.FC<DaysProps> = ({
  days,
  onDayClick,
  selectedDay = "",
  hideFutureDays,
}) => {
  // Disable future  days start date
  const isDayDisabled = (
    dateByDay: number,
    dateByMonth: number,
    dateByYear: number,
  ) => {
    if (hideFutureDays) {
      const date = new Date();
      const day = date.getDate();
      const mon = date.getMonth();
      const year = date.getFullYear();

      return (
        dateByYear > year ||
        (dateByMonth > mon && dateByYear >= year) ||
        (dateByMonth === mon && dateByYear === year && dateByDay > day)
      );
    }
  };

  const generateKey = (pre: number) => {
    return `${pre + Math.random() * pre}_${new Date().getTime()}`;
  };

  const getSelectedDay = (date: Date) => {
    return (
      moment(date).date() === moment(selectedDay).date() &&
      moment(date).month() === moment(selectedDay).month() &&
      moment(date).year() === moment(date).year()
    );
  };
  return (
    <div className="calendar-days-content">
      {days.map((day) => {
        const { date } = day;
        const dateByDay = date.getDate();
        const dateByMonth = date.getMonth();
        const dateByYear = date.getFullYear();
        return (
          <div
            key={generateKey(dateByDay)}
            onClick={() => onDayClick(day)}
            className={`calendar-days-col ${isDayDisabled(dateByDay, dateByMonth, dateByYear) ? "disabled" : ""} ${getSelectedDay(date) ? "selected" : ""}`}
          >
            <p className="calendar-day">{dateByDay}</p>
          </div>
        );
      })}
    </div>
  );
};

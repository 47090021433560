import { action, makeObservable, observable } from "mobx";

import {
  BalanceLimitResponse,
  GetBalanceHistoryRequest,
  LimitOrBalanceUpdateData,
  WalletResponse,
} from "../models";
import { HttpResponse, walletController } from "../api";
import { RootStore } from "./index";
import { DEFAULT_RESPONSE_LIST } from "../constants";

export class WalletStore {
  private rootStore: RootStore;

  public wallet = new HttpResponse<Partial<WalletResponse>>({});
  public balanceLimitData = new HttpResponse<BalanceLimitResponse>(
    DEFAULT_RESPONSE_LIST,
  );

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      wallet: observable,
      balanceLimitData: observable,
      setWallet: action.bound,
      setBalanceLimitHistory: action.bound,
    });
  }

  public setWallet(value: HttpResponse<Partial<WalletResponse>>): void {
    this.wallet = value;
  }

  public setBalanceLimitHistory(
    value: HttpResponse<BalanceLimitResponse>,
  ): void {
    this.balanceLimitData = value;
  }

  public async getWallet(userId: number): Promise<void> {
    this.setWallet(this.wallet.fetching());
    const data = await walletController.getWallet(userId);
    this.setWallet(this.wallet.fetched(data));
  }

  public async getBalanceHistory(
    params: GetBalanceHistoryRequest,
  ): Promise<void> {
    this.setBalanceLimitHistory(this.balanceLimitData.fetching());
    const data = await walletController.getBalanceHistory(params);
    this.setBalanceLimitHistory(this.balanceLimitData.fetched(data));
  }

  public async getLimitHistory(
    params: GetBalanceHistoryRequest,
  ): Promise<void> {
    this.setBalanceLimitHistory(this.balanceLimitData.fetching());
    const data = await walletController.getLimitHistory(params);
    this.setBalanceLimitHistory(this.balanceLimitData.fetched(data));
  }

  public async updateLimit(
    id: number,
    data: LimitOrBalanceUpdateData,
    isWithDraw: boolean,
  ) {
    return isWithDraw
      ? walletController.removeLimit(id, data)
      : walletController.updateLimit(id, data);
  }

  public async updateBalance(
    id: number,
    data: LimitOrBalanceUpdateData,
    isWithDraw: boolean,
  ) {
    return isWithDraw
      ? walletController.removeBalance(id, data)
      : walletController.updateBalance(id, data);
  }
}

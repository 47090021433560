import React, { useMemo } from "react";

import { hexToRGBA } from "../../../../helpers";
import { Icons } from "../../../../enums";
import { Icon } from "../icon";

import "./style.scss";

interface StatusProps {
  text: string;
  icon?: Icons;
  colorHex?: string;
}

export const Status: React.FC<StatusProps> = ({
  icon,
  text = "",
  colorHex = "#000000",
}) => {
  const style = useMemo(() => {
    return {
      color: colorHex,
      background: hexToRGBA(colorHex, 0.08),
      borderColor: hexToRGBA(colorHex, 0.3),
    };
  }, [colorHex]);

  return (
    <div title={text} data-type={text} style={style} className="status">
      {!!icon && <Icon size="xs" type={icon} colorHex={colorHex} />}
      <p className="status-text">{text}</p>
    </div>
  );
};

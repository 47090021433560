import React, { useState, useCallback } from "react";

import { useTranslation } from "../../../../hooks";
import { FilterToggle } from "./filter-toggle";
import { T } from "../../../../constants";
import { Section } from "../section";
import { Button } from "../button";

import "./style.scss";

interface FilterProps {
  onApply: () => void;
  onReset: () => void;
  children: React.ReactNode;
  defaultState?: boolean;
  disableApplyBtn?: boolean;
  disabledFilterButton?: boolean;
}

export const Filter: React.FC<FilterProps> = ({
  onApply,
  onReset,
  children,
  disabledFilterButton,
  defaultState = false,
  disableApplyBtn,
}) => {
  const t = useTranslation();

  const [open, setOpen] = useState(defaultState);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  return (
    <div className="filters">
      <FilterToggle toggle={handleToggle} isDisabled={disabledFilterButton} />
      <Section className={`filters-content ${open ? "open" : ""}`}>
        <div className="filters-row">{children}</div>
        <div className="filters-actions">
          <Button text={t(T.RESET)} onClick={onReset} appearance="secondary" />
          <Button
            text={t(T.APPLY)}
            onClick={onApply}
            disabled={disableApplyBtn}
          />
        </div>
      </Section>
    </div>
  );
};

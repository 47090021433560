import { BaseListRequest } from "../../../models";
import { BaseApiService } from "../../base-api-service";
import { ApiVersions } from "../../../enums";
import { env } from "../../../environment";
import {
  Business,
  BusinessResponse,
  GetBusinessRequest,
} from "../../../models/api/business-settings";

class BusinessesController extends BaseApiService {
  public async getBusinesses(
    params?: BaseListRequest,
  ): Promise<BusinessResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/BusinessUnit`;
    return await this.http.get(url, { params });
  }

  public async editBusinesses(id: number, data: Business): Promise<Business> {
    const url = `${this.url}/api/${ApiVersions.V1}/BusinessUnit/${id}`;
    return await this.http.put(url, data);
  }

  public async getBusinessesById(id: number): Promise<Business> {
    const url = `${this.url}/api/${ApiVersions.V1}/BusinessUnit/${id}`;
    return await this.http.get(url);
  }

  public async getBusinessesByParams(
    params: GetBusinessRequest,
  ): Promise<BusinessResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/BusinessUnit`;
    return await this.http.get(url, { params });
  }

  public async addBusiness(data: Business): Promise<Business> {
    const url = `${this.url}/api/${ApiVersions.V1}/BusinessUnit`;
    return await this.http.post(url, data);
  }

  public async deactivateBusiness(id: number): Promise<void> {
    const url = `${this.url}/api/${ApiVersions.V1}/BusinessUnit/deactivate/${id}`;
    return await this.http.put(url);
  }

  public async activateBusiness(id: number): Promise<void> {
    const url = `${this.url}/api/${ApiVersions.V1}/BusinessUnit/activate/${id}`;
    return await this.http.put(url);
  }
}

export const businessesController = new BusinessesController({
  url: env.httpUrl,
});

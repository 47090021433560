import { action, makeObservable, observable, computed } from "mobx";

import { EditUserSettingsData, UserSettings } from "../models";
import { HttpResponse, userSettingsController } from "../api";
import { RootStore } from "./index";
import { UserType } from "../enums";

export class UserSettingsStore {
  rootStore: RootStore;

  public userSettings = new HttpResponse<Partial<UserSettings>>({});

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      userSettings: observable,
      setUserSettings: action.bound,
      isAdmin: computed,
    });
  }

  public setUserSettings(value: HttpResponse<Partial<UserSettings>>) {
    this.userSettings = value;
  }

  public get isAdmin(): boolean {
    const { userType } = this.userSettings.data;
    return userType === UserType.SuperAdmin || userType === UserType.Admin;
  }

  public async getUserSettings(): Promise<UserSettings> {
    this.setUserSettings(this.userSettings.fetching());
    const data = await userSettingsController.getUserSettings();
    this.setUserSettings(this.userSettings.fetched(data));
    return data;
  }

  public async editUserSettings(data: EditUserSettingsData): Promise<void> {
    const res = await userSettingsController.editUserSettings(data);
    this.setUserSettings(this.userSettings.fetched(res));
  }

  public async resetUserSettings(): Promise<void> {
    this.setUserSettings(this.userSettings.fetched({}));
  }
}

export const formatAmount = (amount: number, fractionDigits = 2): string => {
  if (!amount) return "0";
  return amount
    .toFixed(fractionDigits)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const convertByCurrency = (
  amountByUSD: number,
  rate: number,
): string => {
  const amountByCurrency = amountByUSD / rate;
  return formatAmount(amountByCurrency);
};

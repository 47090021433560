import React, { useCallback, useMemo, useState } from "react";

import { DropdownOptionModel, DropdownOptionValue } from "../models";
import { DropdownOption } from "./dropdown-option";
import { Icons } from "../../../../../enums";
import { Icon } from "../../icon";

import "./style.scss";
import { T } from "../../../../../constants";
import { useTranslation } from "../../../../../hooks";
import { filterData } from "../../../../../helpers/filter-data";

interface DropdownContentProps {
  className: string;
  withSearch?: boolean;
  isMultiselect?: boolean;
  showSelectAll?: boolean;
  isAllSelected?: boolean;
  data: Array<DropdownOptionModel>;
  selectedItems: Array<DropdownOptionValue>;
  onChange: (value: Array<DropdownOptionValue>) => void;
}

export const DropdownContent: React.FC<DropdownContentProps> = ({
  data,
  onChange,
  isMultiselect,
  showSelectAll,
  selectedItems,
  className = "",
  withSearch = false,
  isAllSelected,
}) => {
  const t = useTranslation();
  const [searchValue, setSearchValue] = useState("");

  const selectedItemsMap = useMemo(() => {
    return selectedItems?.reduce<Record<DropdownOptionValue, boolean>>(
      (acc, item) => {
        acc[item] = true;
        return acc;
      },
      {},
    );
  }, [selectedItems]);

  const handleSelect = useCallback(
    (value: DropdownOptionValue) => {
      onChange([value]);
    },
    [isMultiselect, onChange, selectedItemsMap, selectedItems],
  );

  const filteredData = useMemo(() => {
    return filterData(searchValue, data);
  }, [data, searchValue]);

  const handleSelectAll = useCallback(() => {
    onChange(["DROPDOWN_ALL_VALUE"]);
  }, [isAllSelected, data, onChange]);

  const showSearch = withSearch && !!data.length;

  return (
    <div className={`dropdown-content-wr ${className}`}>
      {showSearch && (
        <div className="search-panel">
          <input
            type="text"
            placeholder={t(T.SEARCH)}
            className="search-input"
            onChange={(event) => setSearchValue(event.target.value)}
          />
          <Icon
            className="search-icon"
            type={Icons.Search}
            colorHex="#797a7b"
          />
        </div>
      )}
      <div className="menu">
        {/*<div className="checkbox-search">*/}
        {/*  <div className="input-holder  ">*/}
        {/*    <div className="input-actions">*/}
        {/*      <div className="icon-wr input-action sm "><i className="icon-search"></i></div>*/}
        {/*    </div>*/}
        {/*    <div className="input-item">*/}
        {/*      <input type="text" className="input" placeholder="Search" value=""/>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {showSelectAll && (
          <DropdownOption
            value={t(T.ALL)}
            label={t(T.SELECT_ALL)}
            selected={!!isAllSelected}
            onSelect={handleSelectAll}
            withCheckbox={isMultiselect}
          />
        )}
        <div className="menu-contents">
          <div className="menu-options">
            {filteredData.map(({ value, label, disabled }) => (
              <DropdownOption
                key={value}
                value={value}
                label={label}
                disabled={disabled}
                onSelect={handleSelect}
                withCheckbox={isMultiselect}
                selected={selectedItemsMap[value]}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

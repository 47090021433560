import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { combineQuery } from "../helpers";
import { useForm } from "./use-form";

export const useFilters = (initialFilter: any = {}) => {
  const navigate = useNavigate();
  const {
    handleChange,
    values: filters,
    handleInputChange,
    handleDropdownChange,
    setValues: setFilters,
    handleDatePickerChange,
    handlePositiveNumberChange,
  } = useForm(initialFilter);

  const [isReset, setIsReset] = useState(false);

  useEffect(() => {
    if (Object.keys(filters).length !== 0 && isReset) {
      setIsReset(false);
    }
  }, [filters]);

  const handleApply = useCallback(
    (filter?: any) => {
      navigate(combineQuery(filter || filters));
    },
    [filters, navigate],
  );

  const handleReset = useCallback(() => {
    if (Object.keys(filters).length !== 0) {
      setIsReset(true);
      setFilters(initialFilter);
      navigate("");
    }
  }, [setFilters, navigate, filters]);

  return {
    filters,
    isReset,
    setFilters,
    handleReset,
    handleApply,
    handleChange,
    handleInputChange,
    handleDropdownChange,
    handleDatePickerChange,
    handlePositiveNumberChange,
  };
};

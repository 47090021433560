import React, { useMemo } from "react";

import { TableColumn } from "../view/components/shared/table/models";
import { useTranslation } from "./use-translation";
import { TABLE_DATE_FORMAT } from "../constants";
import { TableColumnType } from "../enums";
import { utcToLocal } from "../helpers";
import { toJS } from "mobx";

const formatters: Record<string, (value: any) => React.ReactNode> = {
  [TableColumnType.Text]: (value: string) => value ?? "-",
  [TableColumnType.Date]: (value: Date) =>
    value ? utcToLocal(value, TABLE_DATE_FORMAT) : "-",
  // TODO: add other formatting (currency)
};

export const useTableColumns = (columns: Array<TableColumn>) => {
  const t = useTranslation();

  const cols = useMemo((): Array<TableColumn> => {
    return columns.map(({ dataKey, type, title, ...rest }) => {
      let formatter = (value: any) => value;
      if (type && formatters[type]) {
        formatter = formatters[type];
      }

      return {
        dataKey: dataKey,
        title: t(title),
        type,
        formatter,
        ...rest,
      };
    });
  }, [columns, t]);

  return {
    cols,
  };
};

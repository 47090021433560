import { useState, useMemo, useCallback, useEffect } from "react";

import { FormElementWithPopover } from "../form-element-with-popover";
import { noop } from "../../../../constants";
import { Icons } from "../../../../enums";
import { Calendar } from "../calendar";

import "./style.scss";

interface DatePickerProps {
  label: string;
  error?: boolean;
  disabled?: boolean;
  hideFutureDays?: boolean;
  readonly?: boolean;
  size?: "sm" | "lg";
  value: Date | string;
  hours?: string;
  onChange?: (value: Date) => void;
  changeHours?: (value: string) => void;
  direction?: "to-top" | "to-bottom";
}

export const DatePicker: React.FC<DatePickerProps> = ({
  size,
  label,
  error,
  value,
  readonly,
  disabled,
  hours,
  onChange = noop,
  changeHours = noop,
  direction = "to-bottom",
  hideFutureDays,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedHours, setSelectedHours] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");

  useEffect(() => {
    if (!open || !hours) {
      if (selectedHours.length < 2 || selectedMinute.length < 2 || !hours) {
        setSelectedHours("");
        setSelectedMinute("");
        changeHours("");
      }
    }
  }, [open, hours]);

  const displayValue = useMemo(() => {
    if (!value) return null;
    return `${value} ${hours || ""}`;
  }, [value, hours]);

  const handleChange = useCallback(
    (date: Date) => {
      onChange(date);
      setOpen(false);
    },
    [onChange],
  );

  return (
    <FormElementWithPopover
      open={open}
      size={size}
      error={error}
      label={label}
      setOpen={setOpen}
      readonly={readonly}
      disabled={disabled}
      value={displayValue}
      icon={Icons.Calendar}
    >
      <div className={`calendar-holder ${direction}`}>
        <Calendar
          value={value}
          selectedHours={selectedHours}
          selectedMinute={selectedMinute}
          setSelectedHours={setSelectedHours}
          setSelectedMinute={setSelectedMinute}
          onChange={handleChange}
          changeHours={changeHours}
          hideFutureDays={hideFutureDays}
        />
      </div>
    </FormElementWithPopover>
  );
};

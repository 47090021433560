import { LoginRequestData, LoginResponse } from "../../../models";
import { BaseApiService } from "../../../api";
import { ApiVersions } from "../../../enums";
import { env } from "../../../environment";

class AuthController extends BaseApiService {
  public async login(data: LoginRequestData): Promise<LoginResponse> {
    const url = `${this.url}/api/${ApiVersions.V1}/token`;
    return await this.http.post(url, data);
  }
  //
  // public async refresh(): Promise<LoginResponse> {
  //   const url = `${this.url}/api/${ApiVersions.V1}/token/refresh`;
  //   return await this.http.post(url);
  // }

  public async refresh() {
    const url = `${this.url}/api/${ApiVersions.V1}/token/refresh`;
    return await fetch(url, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      },
      credentials: 'include',
    });
  }

  public async logout(): Promise<void> {
    const url = `${this.url}/api/${ApiVersions.V1}/token/logout`;
    return await this.http.post(url);
  }
}

export const authController = new AuthController({ url: env.httpUrl });

import { action, makeObservable, observable } from "mobx";

import {
  AddBetshopData,
  Betshop,
  BetshopsResponse,
  EditBetshopData,
  GetBetshopsRequest,
} from "../models";
import { betshopsController, HttpResponse } from "../api";
import { DEFAULT_RESPONSE_LIST } from "../constants";
import { RootStore } from "./index";

export class BetshopsStore {
  private rootStore: RootStore;

  public betshops = new HttpResponse<BetshopsResponse>(DEFAULT_RESPONSE_LIST);

  public betshopsForFilter = new HttpResponse<Array<Betshop>>([]);

  public betshopById = new HttpResponse<Partial<Betshop>>({});

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      betshops: observable,
      betshopById: observable,
      betshopsForFilter: observable,
      setBetshops: action.bound,
      setBetshopById: action.bound,
    });
  }

  public setBetshops(value: HttpResponse<BetshopsResponse>): void {
    this.betshops = value;
  }

  public setBetshopById(value: HttpResponse<Partial<Betshop>>): void {
    this.betshopById = value;
  }

  public setBetshopsForFilter(value: HttpResponse<Array<Betshop>>): void {
    this.betshopsForFilter = value;
  }

  public async getBetshops(params: GetBetshopsRequest): Promise<void> {
    this.setBetshops(this.betshops.fetching());
    const data = await betshopsController.getBetshops(params);
    this.setBetshops(this.betshops.fetched(data));
  }

  public async getBetshopById(id: number): Promise<void> {
    this.setBetshopById(this.betshopById.fetching());
    const data = await betshopsController.getBetshopById(id);
    this.setBetshopById(this.betshopById.fetched(data));
  }

  public async getBetshopsForFilter(
    params?: GetBetshopsRequest,
  ): Promise<void> {
    this.setBetshopsForFilter(this.betshopsForFilter.fetching());
    const data = await betshopsController.getBetshops(params);
    this.setBetshopsForFilter(this.betshopsForFilter.fetched(data.items));
  }

  public async addBetshop(data: AddBetshopData) {
    return await betshopsController.addBetshop(data);
  }

  public async editBetshop(id: number, data: EditBetshopData) {
    return await betshopsController.editBetshop(id, data);
  }

  public async deactivateBetshop(id: number) {
    return await betshopsController.deactivateBetshop(id);
  }

  public async activateBetshop(id: number) {
    return await betshopsController.activateBetshop(id);
  }

  public resetBetShopFilteredData() {
    this.setBetshopsForFilter(this.betshopsForFilter.fetched([]));
  }

  public resetBetshopById() {
    this.setBetshopById(this.betshopById.fetched({}));
  }
}

import React from "react";

import { useTranslation } from "../../../../hooks";

import "./heading-line.scss";

interface HeadingLineProps {
  text?: string;
}

const HeadingLine: React.FC<HeadingLineProps> = ({ text = "" }) => {
  const t = useTranslation();

  return (
    <div className="heading-line-block">
      <p className="heading-line-txt">{t(text)}</p>
      <span className="heading-line" />
    </div>
  );
};

export default HeadingLine;

import { TicketStatus } from "../enums";
import { DropdownOptionModel } from "../view/components/shared/dropdown/models";
import { T } from "./translation-keys";

export const TicketStatusColors = {
  [TicketStatus.Won]: "#4caf50",
  [TicketStatus.Lost]: "#e84855",
  [TicketStatus.Pending]: "#ff8c00",
  [TicketStatus.Returned]: "#7d33db",
  [TicketStatus.Canceled]: "transparent",
};

export const TicketDetailsInfoClassName = {
  [TicketStatus.Won]: "state-green",
  [TicketStatus.Lost]: "state-red",
  [TicketStatus.Pending]: "state-yellow",
  [TicketStatus.Returned]: "state-purple",
  [TicketStatus.Canceled]: "state-red",
};

export const TicketStatusNames = {
  [TicketStatus.Won]: T.WON,
  [TicketStatus.Lost]: T.LOST,
  [TicketStatus.Pending]: T.PENDING,
  [TicketStatus.Returned]: T.RETURNED,
  [TicketStatus.Canceled]: T.CANCELED,
};

export const TicketStatusDropdownData: Array<DropdownOptionModel> = [
  {
    value: TicketStatus.Won,
    label: TicketStatusNames[TicketStatus.Won],
  },
  {
    value: TicketStatus.Lost,
    label: TicketStatusNames[TicketStatus.Lost],
  },
  {
    value: TicketStatus.Pending,
    label: TicketStatusNames[TicketStatus.Pending],
  },
  {
    value: TicketStatus.Returned,
    label: TicketStatusNames[TicketStatus.Returned],
  },
  {
    value: TicketStatus.Canceled,
    label: TicketStatusNames[TicketStatus.Canceled],
  },
];

import { getCurrentLanguageCode } from "../helpers";
import { rootStore } from "../store";
import { UserType } from "../enums";
import { IS_UNIVERSAL_PARTNER } from "../constants/host-name";

class AppService {
  public async init(): Promise<void> {
    const {
      translationsStore,
      walletStore,
      currenciesStore,
      userSettingsStore,
      partnerStore,
      businessStore,
    } = rootStore;

    const [user] = await Promise.all([
      userSettingsStore.getUserSettings().then((result) => {
        if (result.userType === UserType.Manager && result.businessUnitId) {
          businessStore.getBusinessById(result.businessUnitId);
        }
        return result;
      }),
      currenciesStore.getCurrencies(),
    ]);

    const languages = await translationsStore.getLanguages();

    const defaultLan = languages.find((lan) => lan.isDefault);

    translationsStore.getTranslations({
      code: IS_UNIVERSAL_PARTNER
        ? getCurrentLanguageCode()
        : getCurrentLanguageCode(defaultLan?.uid),
    });

    const calls = [];

    if (
      user.userType !== UserType.SuperAdmin &&
      user.userType !== UserType.Admin
    ) {
      calls.push(walletStore.getWallet(user.id));
    }

    if (user.userType === UserType.Partner) {
      calls.push(partnerStore.getPartnerBusiness());
    }

    await Promise.all(calls);
  }
}

export const appService = new AppService();

import { memo, useMemo } from "react";
import { useTranslation } from "../../../../../hooks";
import { Icons, UserType } from "../../../../../enums";
import { Currency, Icon } from "../../../shared";
import { T } from "../../../../../constants";

interface UserWalletProps {
  amount: number;
  userType?: number;
  currency?: string;
  iconType: Icons;
  isBalance?: boolean;
}

// TODO transfer to constants file and change type

const BALANCE_WALLET_TITLE: any = {
  [UserType.Manager]: T.BALANCE,
  [UserType.Partner]: T.BUSINESS_BALANCE,
};

const LIMIT_WALLET_TITLE: any = {
  [UserType.Manager]: T.LIMIT,
  [UserType.Partner]: T.BUSINESS_LIMIT,
};

const UserWallet: React.FC<UserWalletProps> = ({
  amount,
  currency,
  iconType,
  userType,
  isBalance = false,
}) => {
  const t = useTranslation();

  const getWalletTitle = useMemo(() => {
    let title = "";
    if (userType) {
      if (isBalance) {
        title = BALANCE_WALLET_TITLE[userType];
      } else {
        title = LIMIT_WALLET_TITLE[userType];
      }
    }
    return title;
  }, [userType, isBalance]);

  return (
    <div className="info-block">
      <Icon type={iconType} className="balance-icon" />
      <div className="balance-info">
        <p className="balance-title">{t(getWalletTitle)}</p>
        <p className="balance-amount">
          <Currency value={amount} convert={false} currencyCode={currency} />
        </p>
      </div>
    </div>
  );
};

export default memo(UserWallet);

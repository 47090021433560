import React from "react";

import { Icons } from "../../../../../enums";
import { TableTitleAction } from "../models";
import { Icon } from "../../icon";

interface TableTitleProps {
  title: string;
  onRefreshClick?: () => void;
  titleActions?: Array<TableTitleAction>;
}

export const TableTitle: React.FC<TableTitleProps> = ({
  title,
  titleActions,
  onRefreshClick,
}) => {
  return (
    <div className="table-title-wr">
      <p className="table-title">{title}</p>
      <div className="actions">
        {titleActions?.map(({ icon, onClick }) => (
          <Icon
            key={icon}
            type={icon}
            onClick={onClick}
            className="table-title-action"
          />
        ))}
        {/*<Icon*/}
        {/*    type={Icons.Export}*/}
        {/*    // onClick={onRefreshClick}*/}
        {/*    className="table-title-action"*/}
        {/*/>*/}

        {onRefreshClick ? (
          <Icon
            type={Icons.Refresh}
            onClick={onRefreshClick}
            className="table-title-action"
          />
        ) : null}
      </div>
    </div>
  );
};

import React from "react";

import { TableColumn, TableRowAction, TableTitleAction } from "./models";
import { useTableColumns } from "../../../../hooks";
import { TableContent } from "./table-content";
import { noop } from "../../../../constants";
import { TableHeader } from "./table-header";
import { TableFooter } from "./table-footer";
import { TableTitle } from "./table-title";

import "./style.scss";
import { Ticket } from "../../../../models";

export interface TableProps {
  page: number;
  title: string;
  rowKey?: string;
  rowsCount: number;
  loading?: boolean;
  selectorValue: number;
  onRefreshClick?: () => void;
  columns: Array<TableColumn>;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
  rows: Array<Record<string, any>>;
  titleActions?: Array<TableTitleAction>;
  totals?: Record<string, React.ReactNode>;
  onRowClick?: (row: any, index: number) => void;
  onPaginationChange: (page: number, selectorValue: number) => void;
  getRowActions?: (row: any, index: number) => Array<TableRowAction>;
}

export const Table: React.FC<TableProps> = ({
  rows,
  page,
  totals,
  columns,
  rowsCount,
  title = "",
  leftActions,
  titleActions,
  rightActions,
  getRowActions,
  selectorValue,
  rowKey = "id",
  onRefreshClick,
  loading = false,
  onRowClick = noop,
  onPaginationChange,
}) => {
  const { cols } = useTableColumns(columns);
  const pagesCount = Math.ceil(rowsCount / selectorValue);
  const showFooter = !loading && !!rowsCount;

  return (
    <div className="table">
      <TableTitle
        title={title}
        titleActions={titleActions}
        onRefreshClick={onRefreshClick}
      />
      <div className="table-content">
        <TableHeader leftActions={leftActions} rightActions={rightActions} />
        <TableContent
          rows={rows}
          columns={cols}
          rowKey={rowKey}
          totals={totals}
          loading={loading}
          noData={!rowsCount}
          onRowClick={onRowClick}
          getRowActions={getRowActions}
        />
      </div>
      {showFooter && (
        <TableFooter
          currentPage={page}
          rowsCount={rowsCount}
          pagesCount={pagesCount}
          pageRowsCount={rows.length}
          selectorValue={selectorValue}
          onPaginationChange={onPaginationChange}
        />
      )}
    </div>
  );
};
